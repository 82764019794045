import React, { useState, useEffect, useRef } from 'react';
import './ProductSearch.css';
import productData from './productsData';

function ProductSearch() {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(productData);
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const filterProducts = () => {
    const results = productData
      .filter((product) => {
        const searchInName = product.name.toLowerCase().includes(searchTerm.toLowerCase());
        const searchInKeywords =
          product.searchKeyWords?.some((keyword) =>
            keyword.toLowerCase().includes(searchTerm.toLowerCase())
          ) || false; // Ensure `searchKeyWords` exists
        return searchInName || searchInKeywords;
      })
      .sort((a, b) => b.id - a.id); // Sort results by ID in descending order

    setFilteredProducts(results);

    // Scroll to the top whenever search results are updated
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      filterProducts();
    }, 300);

    return () => clearTimeout(debounceTimeout);
  }, [searchTerm]);

  const clearSearch = () => {
    setSearchTerm('');
    setFilteredProducts(productData);
    // Scroll to top when clearing the search
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="product-search-container">
      <h2>Monitor Munchies</h2>
      <p className="search-instructions">Search reels here to find & watch on Instagram!</p>
      <div className="sticky-container">
        <div className="search-bar">
          <input
            ref={inputRef}
            type="text"
            placeholder="Type to find any reel"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="styled-input"
          />
          {searchTerm && (
            <button className="clear-button" onClick={clearSearch}>
              Clear
            </button>
          )}
        </div>
      </div>

      <div className="product-grid">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <a
              key={product.id}
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
              className="product-card"
            >
              <div className="image-container">
                {product.img ? (
                  <>
                    <img src={product.img} alt={product.name} className="product-image" />
                  </>
                ) : (
                  <div className="default-image-container">
                    <img
                      src="https://barkasbiryaniimages.s3.us-east-1.amazonaws.com/profilePicInsta.jpg"
                      alt="Default"
                      className="product-image"
                    />
                    <h3 className="product-name-center">{product.name}</h3>
                  </div>
                )}
              </div>
            </a>
          ))
        ) : (
          <p className="no-results">No reels found</p>
        )}
      </div>

      <button className="scroll-to-top" onClick={scrollToTop}>
        ↑
      </button>
    </div>
  );
}

export default ProductSearch;